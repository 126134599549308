<template>
   <div class="cont activityBox">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item to="/activity_list/WECHAT">小程序弹窗活动</el-breadcrumb-item>
            <el-breadcrumb-item>{{ action === 'add' ? '创建' :  action === 'edit' ?  '编辑'  : '复制'}}小程序弹窗活动</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 返回页头 -->
      <el-page-header @back="$router.go(-1)" :content="`${ action === 'add' ? '创建' :  action === 'edit' ?  '编辑'  : '复制' }小程序弹窗活动`"></el-page-header>
      <!-- 主体内容 -->
      <div class="cont-form-box">
         <!-- 步骤条 -->
         <el-steps :active="active" simple>
            <el-step title="01.活动基础配置"></el-step>
            <el-step title="02.活动内容"></el-step>
            <el-step title="03.活动预览"></el-step>
         </el-steps>
         <!-- 第一步 -->
         <div class="first-step" v-show="active === 0">
            <activity-config @nextStep="nextStep" :activityCycle="ruleForm.activityCycle">
               <template v-slot:activityCycle>
                  <el-form-item label="活动周期" prop="activityCycle" >
                     <el-date-picker
                           class="width-370"
                           v-model="ruleForm.activityCycle"
                           type="daterange"
                           value-format="yyyy-MM-dd HH:mm:ss"
                           range-separator="至"
                           start-placeholder="开始日期"
                           end-placeholder="结束日期"
                           :clearable="false">
                     </el-date-picker>
                  </el-form-item>
               </template>
               <template v-slot:pushRule>
                  <el-form-item label="推送规则">
                     <div>
                        <el-radio v-model="ruleForm.pushRule" label="APPLET_EVENTS">基于小程序启动事件触发</el-radio>
                     </div>
                     <div class="push-rule" v-if="ruleForm.pushRule === 'APPLET_EVENTS'">
                     </div>
                  </el-form-item>
                  <el-form-item label="当用户启动小程序后">
                     <el-select class="width-200" @change="changeWaitSend" v-model="ruleForm.isWaitSend"  placeholder="请选择">
                        <el-option
                              v-for="item in [{val: 'NO', name: '立即显示弹窗'},/*{val: 'YES', name: '在用户后续访问到指定页面时弹窗'}*/]"
                              :key="item.val"
                              :label="item.name"
                              :value="item.val">
                        </el-option>
                     </el-select>
                  </el-form-item>
<!-- 暂时注释 -->
<!--                    <span style="margin-left: 10px" v-show="ruleForm.isWaitSend === 'YES'">
                       <el-select class="width-140" v-model="ruleForm.tiggerConditionId"  placeholder="请选择">
                          <el-option
                                v-for="item in targetList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                          </el-option>
                       </el-select>
                       <span style="font-size: 12px; line-height: 28px;margin: 0 6px">等于</span>
                       <el-input class="width-120"  v-model="ruleForm.tiggerConditionValue"></el-input>
                    </span>-->
                  <!-- 弹出频率 -->
                 <popup-rate ref="pupupRate" ></popup-rate>
               </template>
            </activity-config>
         </div>
         <!-- 第二步 -->
         <div v-show="active === 1">
            <operate-setcontent ref="operateSetcontent" :active="active" @backPage="backPage" @nextStep="nextStep"></operate-setcontent>
         </div>
         <!-- 第三步 -->
         <el-row class="third-step" v-if="active === 2">
            <el-col :span="10" class="third-step-left">
               <el-row class="img">
                  <el-row class="dynamic-img">
                     <img :src="ruleForm.mediaPath" alt="">
                  </el-row>
                  <img src="@/assets/create_applet_img.jpg" alt="" >
               </el-row>
            </el-col>
            <el-col :span="14" class="third-step-right">
               <ul>
                  <li><span class="c-grey">活动名称：</span>{{ruleForm.eventName}}</li>
                  <li><span class="c-grey">发送平台：</span>{{ruleForm.eventName}}</li>
                  <li><span class="c-grey">目标用户：</span>微信小程序</li>
                  <li><span class="c-grey">开始时间：</span>{{ruleForm.activityCycle[0]}}</li>
                  <li><span class="c-grey">结束时间：</span>{{ruleForm.activityCycle[1]}}</li>
                  <li><span class="c-grey">推送规则：</span>{{ruleForm.pushRule | filterPushRule}}</li>

                  <li><span class="c-grey">弹出频率：</span>
                     <span>若用户点击弹窗，下次触发相同条件时，弹窗将会 <b style="color: red">{{ruleForm.clickWinAction | filterWin}}</b></span>
                     <p style="font-size: 13px;margin-left: 70px;">若用户关闭弹窗，下次触发相同条件时，弹窗将会 <b style="color: red">{{ruleForm.closeWinAction | filterWin}}</b></p>
                  </li>
                  <li><span class="c-grey">插图链接：</span><span>{{ruleForm.mediaPath}}</span></li>
                  <li>
                     <el-button style="margin-top: 12px" @click="active --" >上一步</el-button>
                     <el-button class="bg-gradient"  @click="handleSave">开始活动</el-button>
                     <el-button class="bg-gradient" type="primary"  @click="handleSave('DRAFT')">保存草稿</el-button>
                  </li>
               </ul>
            </el-col>
         </el-row>
      </div>

   </div>
</template>

<script>
import { urlObj  } from '@/api/interface';
import { mapState } from 'vuex';
import activityConfig from '@/components/local/activityConfig';
import { operate } from  "@/api/interface/smtech";
import popupRate from "@/components/global/popupRate";
import  operateSetcontent from  "@/components/global//operateSetcontent";
export default {
   name: "createPopup",
   components:{ activityConfig, popupRate,operateSetcontent },
   computed: { ...mapState(['hotelInfo']) },
   data(){
      return{
         action:'add',
         active: 0, //步骤
         platform:'',

         ruleForm:{
            activityCycle:[],          // 活动周期
            pushRule:'APPLET_EVENTS',  // 推送规则
            isWaitSend:'NO',
            mediaPath: '',
            // tiggerConditionId:'',
            // tiggerConditionValue:'',
            file:null,
            state:'IN_PROGRESS',
         },
         // rules: {
         //    activityCycle :[{ required: true, validator: validActive, trigger: 'change' }],
         // },
         targetList:[], // 转换目标列表数据
         bool:true,
         id:'',
      }
   },
   mounted() {
      this.action = this.$route.query.action
      let path = this.$route.path
      this.platform = path.substring(path.indexOf("_") + 1).toLocaleUpperCase()
      if(this.action !== 'add') this.getEditInfo()
   },
   methods:{
      getEditInfo(){
         const activityObj = JSON.parse(sessionStorage.getItem('activityObj') || '{}')
         if(activityObj.beginDate){
            this.ruleForm.activityCycle = []
            this.ruleForm.activityCycle.push(activityObj.beginDate, activityObj.endDate)
         }
      },

      // 开始活动
      handleSave(state){
         let param = new FormData()
         if (this.ruleForm.file) {
            // this.ruleForm.file = file.raw
            param.append("file", this.ruleForm.file); // 文件
            param.append('module', 'system')         // 所在模块
            param.append('menu', 'company')          // 所属菜单
            param.append('func', 'logo')             // 图片功能
            param.append('category', 'logo')         // 图片分类
            param.append('isThumb', true)            // 是否开启缩略图
         }
         param.append('hotelId', this.hotelInfo.id)
         param.append('companyId', this.hotelInfo.storeId)
         param.append('eventName', this.ruleForm.eventName)
         param.append('beginDate', this.ruleForm.activityCycle[0])
         param.append('endDate', this.ruleForm.activityCycle[1])
         param.append('isConvert', this.ruleForm.isConvert)    // 转化跟踪
         if(this.ruleForm.isConvert === 'YES') {
            param.append('targetId', this.ruleForm.targetId)
            param.append('days', this.ruleForm.days)
         }
         param.append('groupIds', this.ruleForm.groupList.map(item => item.groupId).toString())
         param.append('pushRule', this.ruleForm.pushRule)
         param.append('isWaitSend', this.ruleForm.isWaitSend)
         param.append('eventWechatDTO.clickWinAction', this.ruleForm.clickWinAction)
         param.append('eventWechatDTO.closeWinAction', this.ruleForm.closeWinAction)
         param.append('platform', this.platform)
         if (state === 'DRAFT') this.ruleForm.state = state
         param.append('state', this.ruleForm.state)
         param.append('eventWechatDTO.type', 'APPLETS_IMG_POPUP')
         param.append('eventWechatDTO.textType', this.ruleForm.textType)
         if(this.ruleForm.textType === "COUPON" || this.ruleForm.textType === "COUPON_PRESELL") param.append('eventWechatDTO.couponId', this.ruleForm.couponId)
         else param.append('eventWechatDTO.pagePath', this.ruleForm.pagePath)

         let url = (this.action === 'edit' && this.ruleForm.state !== 'DRAFT' ? operate.editActivity : operate.createActivity)
         if (this.action === 'edit' && this.ruleForm.state !== 'DRAFT') param.append('id', this.ruleForm.id)
         this.$axios.post(url, param, 'file').then(res => {
            if (res.success) {
               this.$message({
                  showClose: true,
                  message: '创建成功！',
                  type: 'success'
               })
               this.goBack()
            }
         })
      },

      // 获取 转换目标数据
      // getTargetList(category){
      //    const url = urlObj.metadataList
      //    const param = { category }
      //    this.$axios.post(url, param).then(res => {
      //       if (res.success) this.targetList = res.records
      //    })
      // },

      changeWaitSend(){ },
      // 检验活动名称是否重复
      checkNameIsExist() {
         return new Promise((resolve) => {
            const url = operate.checkNameIsExist
            const param = { eventName: this.ruleForm.eventName, companyId: this.hotelInfo.storeId }
            this.$axios.post(url, param).then(res => {
               if (!res.success) return resolve(true);
               resolve(res.records)
            })
         })
      },

      // 上一步
      backPage(activeNum){ this.active = activeNum-1 },

      // 下一步
      async nextStep(ruleForm) {
         // 点击关闭 弹出数据
         this.ruleForm = Object.assign({}, this.ruleForm, ruleForm)
         if(this.active === 0) this.ruleForm = Object.assign({}, this.ruleForm, this.$refs.pupupRate.ruleForm)
         if(this.action !== 'edit'){
            if (await this.checkNameIsExist()) {
               return this.$message.error('活动名称不能重复')
            }
         }
         // if(this.active === 1 )
         this.active ++
      },
      // 返回上页
      goBack(){
         this.$router.go(-1)
      }
   },
   filters: {
      filterWin(val){
         switch (val) {
            case 'TEXT':
               return val = '不再弹出'
            case 'IMG':
               return val = '限制次数内弹出'
            case 'VOICE':
               return val = '每次弹出'
         }
      },
      filterPushRule(val){
         switch (val) {
            case 'APPLET_EVENTS':
               return val = '基于小程序启动事件触发'
            case 'USER_EVENT_SHOW':
               return val = '基于用户事件触发显示'
         }
      }
   },
}
</script>

<style scoped lang="scss">
.activityBox{
   .cont-form-box{
      // 第三步
      .third-step{
         &-left{
            padding: 40px 0;
            text-align: center;
            height: auto;
            background-color: #f8f5ff;
            .img{
               width: 254px;
               margin: 0 auto;
               position: relative;
               .dynamic-img{
                  width: 100%;
                  top: 30%;
                  padding: 0 10px;
                  position: absolute;
                  img{
                     width: 100%;
                  }
               }
            }
         }
        &-right{
           width: 50%;
           height: 600px;
           background: white;
           float: left;
           ul {
              list-style: none;
              margin-top: 3rem;
              margin-left: 3rem;
              li {
                 line-height: 40px;
                 span {
                    font-size: 14px;
                 }
              }
           }
        }
      }
   }
}
</style>

